import { useFetch } from "@kanpla/system";
import { PaymentGateway } from "@kanpla/types";
import React from "react";

interface Props {
  fileName: "privacy_policy" | "terms_of_trade";
  defaultLink: string;
  paymentGatewayId?: PaymentGateway["id"];
  children: React.ReactNode; // for rendering any content inside the button
  className?: string;
}

export interface CustomLegalLinkArgs {
  paymentGatewayId?: PaymentGateway["id"];
  fileName: Props["fileName"];
}

export interface CustomLegalLinkData {
  url: string | undefined;
}

export const CustomLegalLinkButton = ({
  fileName,
  defaultLink,
  paymentGatewayId,
  children,
  className = "inline-block underline",
}: Props) => {
  const { data } = useFetch<CustomLegalLinkArgs, CustomLegalLinkData>(
    "signup/customLegalLink",
    {
      fileName,
      paymentGatewayId,
    },
    { refreshInterval: 30 * 60 * 1000, revalidateOnFocus: false }
  );

  return (
    <a
      href={data?.url || defaultLink}
      className={className}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

import { T } from "@kanpla/system";
import { PaymentGateway } from "@kanpla/types";
import React from "react";
import { CustomLegalLinkButton } from "./CustomLegalLinkButton"; // Ensure this import path is correct

interface Props {
  paymentGatewayId?: PaymentGateway["id"];
}

export const PrivacyPolicy: React.FC<Props> = ({ paymentGatewayId }) => {
  return (
    <CustomLegalLinkButton
      fileName="privacy_policy"
      defaultLink="https://kanpla.io/privacy-policy"
      paymentGatewayId={paymentGatewayId}
    >
      <T _str="Privacy Policy" />
    </CustomLegalLinkButton>
  );
};

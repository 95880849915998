import { T } from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import { Button, Image, NewLogo } from "@kanpla/ui";
import classNames from "classnames";
import React from "react";
import { useIntercom } from "react-use-intercom";
import { useContainer } from "unstated-next";
import { URL_LOGIN } from "../pages/login";
import { URL_SIGNUP } from "../pages/signup";
import { AppContext } from "./contextProvider";

const Quote = ({ quote }: { quote?: string }) => {
  if (!quote) return null;
  return (
    <p
      className={classNames(
        "text-white text-center my-8 text-balance break-words line-clamp-3 text-ellipsis",
        {
          "text-5xl": quote.length < 30,
          "text-3xl": quote.length >= 30 && quote.length < 65,
          "text-2xl": quote.length >= 65,
        }
      )}
    >
      {quote}
    </p>
  );
};

const Landing = ({ supplier }: { supplier?: Supplier }) => {
  const { show } = useIntercom();

  // TODO: refactor image src handling to srcset or similar
  const { mobile } = useContainer(AppContext);
  const landingBackgroundType = mobile ? "mobile" : "desktop";
  const landingBackgroundImage =
    supplier?.custom?.images?.landingBackgroundImage;
  const backgroundImage =
    landingBackgroundImage?.[landingBackgroundType] ||
    // If mobile image is not available, fallback to the desktop one
    (mobile && landingBackgroundImage?.["desktop"]) ||
    supplier?.custom?.images?.landingBackground ||
    "/images/cooking-hero-join.jpeg";

  const quote = supplier?.custom?.landing?.quote;

  return (
    <div id="landing-app-fullscreen" className="w-full h-full relative">
      <div className="z-0 absolute inset-0">
        <div className="bg-primary-main bg-opacity-30 absolute inset-0 z-10" />
        <div className="bg-gradient-to-tl from-black opacity-70 absolute inset-0 z-10" />
        <Image
          src={backgroundImage}
          alt=""
          className="absolute inset-0 z-0 w-full h-full object-cover"
        />
      </div>
      <div className="relative flex flex-col max-w-sm min-h-screen mx-auto p-4 z-40 justify-between">
        <div className="self-center flex-1 flex flex-col justify-center">
          <NewLogo
            centered
            type="white"
            style={{ maxWidth: 200 }}
            allowKeepTrueColorCheck={false}
          />
        </div>
        <Quote quote={quote} />
        <div className="flex flex-col gap-4">
          <Button
            type="white"
            shape="solid"
            size="large"
            href={URL_SIGNUP}
            className="w-full flex justify-center"
          >
            <T _str="Create account" />
          </Button>
          <Button
            type="white"
            shape="soft"
            size="large"
            href={URL_LOGIN}
            className="w-full flex justify-center"
          >
            <T _str="Log in" />
          </Button>
        </div>
        <div className="flex-1 flex flex-col justify-end">
          <Button
            onClick={show}
            type="white"
            shape="plain"
            className="self-end md:self-center"
          >
            <T _str="Need help?" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Landing;

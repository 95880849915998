const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

/** Better than grouping, will sort numbers in strings etc.
 * - Use e.g. for sorting of `groupNames`
 */
export const humanSort = (data: Array<string>) => data.sort(collator.compare);

/** Better than grouping, will sort numbers in strings etc.
 * - Use e.g. for sorting of `groupNames`
 */
export const humanSortBy = <T>(
  data: T[],
  keySelector: (item: T) => string
): T[] => {
  return data.sort((a, b) => collator.compare(keySelector(a), keySelector(b)));
};

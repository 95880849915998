import classNames from "classnames";
import Image from "next/image";
import React from "react";

interface Props {
  dark?: boolean;
  white?: boolean;
  center?: boolean;
  /** If the branding is setup on a specific school */
  schoolId?: string | null;
  /** Allows to check if the logo should use true color (no CSS filters) but in some cases it shouldn't be allowed anyway because of components with colored background */
  allowKeepTrueColorCheck?: boolean;
  /** Get horizonal version of the logo, if existing. Defaults to `false` */
  horizontal?: boolean;
}

export const Logo = (props: Props) => {
  const {
    dark,
    white,
    center = true,
    schoolId,
    allowKeepTrueColorCheck = true,
    horizontal = false,
  } = props;

  const logoImage = schoolId
    ? `/api/logo?schoolId=${schoolId}&format=png&horizontal=${horizontal}`
    : `/api/logo?format=png&horizontal=${horizontal}`;

  if (!logoImage?.includes("https://"))
    return (
      <img
        src={logoImage}
        alt="App logo"
        className={classNames(
          "h-auto w-full max-h-full min-h-[2rem] max-w-full object-contain",
          {
            "brand-icon": !allowKeepTrueColorCheck,
          },
          { dark: dark },
          { white: white },
          center ? "object-center" : "object-left"
        )}
      />
    );

  return (
    <Image
      src={logoImage}
      alt="App logo"
      className={classNames(
        "max-h-full min-h-[2rem] max-w-full object-contain",
        {
          "brand-icon": !allowKeepTrueColorCheck,
        },
        { dark: dark },
        { white: white },
        center ? "object-center" : "object-left"
      )}
    />
  );
};

export default Logo;
